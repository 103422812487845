<template>
  <div>
    <h2>商品</h2>
    <div>
      <div class="bp-ms">
        <h4>品类销量排行</h4>
        <a href="javascript:;" @click="showView('class')">
          查看详情
        </a>
      </div>
      <ve-histogram
        :data="classData"
        :colors="colors"
        :y-axis="yAxis"
        :grid="grid"
      ></ve-histogram>
    </div>

    <!-- 表格 -->
    <div>
      <div class="shop-bb">
        <h4>商品销量排行</h4>
        <div>
          <el-button
            size="mini"
            :class="type === 1?'active':''"
            round
            @click="selectType(1)"
          >销量</el-button>
          <el-button
            size="mini"
            :class="type === 2?'active':''"
            round
            @click="selectType(2)"
          >金额</el-button>
          <a href="javascript:;"
          style="text-decoration: none;
                  margin-left:10px;
                  color: #017aff !important;" @click="showView('shop')">
            查看详情
          </a>
        </div>
      </div>
      <el-table
        :data="shopData"
        class="report-shop-table"
        height="600"
        style="width: 100%;background#f8f8f8;"
      >
        <el-table-column
          prop="name"
          width="230"
          label=""
        >
        </el-table-column>

        <el-table-column
          label=""
        >
        <template slot-scope="scope">
          <el-progress
          text-inside
          :show-text="false"
          :color="'#222'" :percentage="isSum == true?scope.row.zb_sum:scope.row.zb_price"></el-progress>
        </template>
        </el-table-column>

        <el-table-column
          :prop="isSum == true?'sum':'price'"
          :label="isSum == true?'数量':'金额'"
          sortable
          width="180"
        >
        </el-table-column>

        <el-table-column
          sortable
          :prop="isSum == true?'zb_sum':'zb_price'"
           width="100"
          label="占比"
        >
          <template slot-scope="scope">
            {{isSum == true?scope.row.zb_sum:scope.row.zb_price}}%
          </template>
        </el-table-column>
        <el-table-column
          sortable
          :prop="isSum == true?'tb_sum':'tb_price'"
          label="同比"
          width="120"
        >
          <template slot-scope="scope">
            <span :style="isSum == true?scope.row.tb_sum_color:scope.row.tb_price_color">{{isSum == true?scope.row.tb_sum_fh:scope.row.tb_price_fh}}
            {{isSum == true?scope.row.tb_sum:scope.row.tb_price}}%
            </span>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          :prop="isSum == true?'hb_sum':'hb_price'"
          label="环比"
           width="120"
        >
          <template slot-scope="scope">
            <span :style="isSum == true?scope.row.hb_sum_color:scope.row.hb_price_color">{{isSum == true?scope.row.hb_sum_fh:scope.row.hb_price_fh}}
            {{isSum == true?scope.row.hb_sum:scope.row.hb_price}}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reportShop',
  props: {
    shop: {
      type: Object
    }
  },
  data () {
    this.colors = ['#017AFF',
      '#FFE6C3']
    this.grid = {
      show: true
    }
    this.yAxis = {
      splitLine: {
        show: false
      }
    }
    return {
      type: 1,
      isSum: true,
      shopData: null,
      classData: null
    }
  },
  mounted () {
    this.shopData = this.shop.sumShop
    this.classData = this.shop.zhu
  },
  watch: {
    shop (shop) {
      this.classData = shop.zhu
      this.shopData = shop.sumShop
    }
  },
  methods: {
    selectType (id) {
      if (id === 2) {
        this.isSum = false
      } else {
        this.isSum = true
      }
      this.type = id
    },
    showView (val) {
      this.$emit('showView', val)
    }
  }
}
</script>
<style lang='scss' scoped>
.report-shop-table{
  overflow-y: auto;
  max-height: 700px;
}
.shop-bb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.active {
    color: #409eff;
    background: #ecf5ff;
    border-color: #b3d8ff;
}
.a{
  margin-left: 10px;
  text-decoration: none;
  color:#409eff;
}
</style>
