<template>
  <div >
    <h2>会员</h2>
    <div class="bing">
      <div>
        <div class="bp-ms">
          <h4>会员来源</h4>
          <a href="javascript:;" @click="showView('member-form')">
            查看详情
          </a>
        </div>
        <ve-histogram
          :y-axis="yAxis"
          :grid="grid"
          :colors="colors"
          :data="storeData"
        ></ve-histogram>
      </div>
      <div style="margin:0 50px;">
        <h4>会员组成</h4>
        <div>
          <el-button
            size="mini"
            :class="type === 1?'active':''"
            round
            @click="selectType(1)"
          >人数</el-button>
          <el-button
            size="mini"
            :class="type === 2?'active':''"
            round
            @click="selectType(2)"
          >金额</el-button>
        </div>
        <ve-ring
          v-if="type === 1"
          :colors="colors"
          :extend="chartExtendOne"
          :data="memberDataOne"
        ></ve-ring>

        <ve-ring
          v-if="type === 2"
          :colors="colors"
          :extend="chartExtendTwo"
          :data="memberDataTwo"
        ></ve-ring>
      </div>
    </div>
    <div v-if="rb">
      <h4>会员储值</h4>
      <div class="body rb">
      <div class="rb-box">
        <div class="rb-left">
          <div class="rb-t"><span :style="'background:'+rb.cz_color"></span>储值总余额（元）</div>
          <div class="rb-des">
            <div>环比 {{rb.cz_hb}}</div>
            <div class="tb">同比 {{rb.cz_tb}}</div>
          </div>
        </div>
        <div class="rb-right" :style="'color:'+rb.cz_color">{{rb.cz_price[0]}}<span>.{{rb.cz_price[1]}}</span></div>
      </div>
      <div class="rb-box">
        <div class="rb-left">
          <div class="rb-t"><span :style="'background:'+rb.xf_color"></span>当期消费（元）</div>
          <div class="rb-des">
            <div>环比 {{rb.xf_hb}}</div>
            <div class="tb">同比 {{rb.xf_tb}}</div>
          </div>
        </div>
        <div class="rb-right" :style="'color:'+rb.xf_color">{{rb.xf_price[0]}}<span>.{{rb.xf_price[1]}}</span></div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reportOrder',
  props: {
    member: {
      type: Object
    }
  },
  data () {
    this.colors = ['#017AFF',
      '#FFE6C3', '#ddd']
    this.grid = {
      show: true
    }
    this.yAxis = {
      splitLine: {
        show: false
      }
    }
    this.chartExtendOne = {
      legend: {
        orient: 'vertical',
        x: 'right'
      },
      series: {
        name: '消费占比',
        type: 'pie',
        label: {
          normal: {
            formatter: '{b} {d}% \n{@[0]} 人',
            color: '#333333'
          }
        }
      }
    }
    this.chartExtendTwo = {
      legend: {
        orient: 'vertical',
        x: 'right'
      },
      series: {
        name: '消费占比',
        type: 'pie',
        label: {
          normal: {
            formatter: '{b} {d}% \n{@[0]} 元',
            color: '#333333'
          }
        }
      }
    }
    return {
      type: 1, // 数目还是金额
      memberDataOne: null,
      memberDataTwo: null,
      storeData: null,
      rb: null
    }
  },
  mounted () {
    this.memberDataOne = this.member.make.sum
    this.memberDataTwo = this.member.make.price
    this.storeData = this.member.form
    this.rb = this.member.price
  },
  watch: {
    member (member) {
      this.memberDataOne = member.make.sum
      this.memberDataTwo = member.make.price
      this.storeData = member.form
      this.rb = member.price
    }
  },
  methods: {
    selectType (id) {
      if (id === 2) {
        this.isSum = false
      } else {
        this.isSum = true
      }
      this.type = id
    },
    showView (val) {
      this.$emit('showView', val)
    }
  }
}
</script>
<style lang='scss' scoped>
.bing {
    display: flex;
    div {
        width: 50%;
    }
}
.active {
    color: #409eff;
    background: #ecf5ff;
    border-color: #b3d8ff;
}
.user-progress {
    width: 100%;
    height: 20px;
    position: relative;
    .sum {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: #017AFF;
    }
    .yxf {
        position: absolute;
        right: 0;
        height: 100%;
        background: white;
        z-index: 2;
        border: 1px dotted #d5d5d5;
    }
    .xcz {
        height: 14px;
        top: 3px;
        position: absolute;
        z-index: 3;
    }
    .xzs {
        height: 8px;
        top: 6px;
        position: absolute;
        z-index: 4;
    }
}
.rb-box{
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rb-left .rb-right{
    width: 50%;
  }
  .rb-t{
    font-size: 26rpx;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span{
      margin-right: 5px;
      width: 12.5px;
      height:12.5px;
    }
  }
  .rb-right{
    font-size: 24px;
    span{
      font-size: 15px;
    }
  }
  .rb-left{
    .rb-des{
      font-size: 10px;
      display: flex;
      justify-content: flex-start;
      color:#9A9A9A;
      .tb{
        margin-left: 10px;
      }
    }
  }
}
</style>
