<template>
    <div>
        <div class="real-b-h bp-ms">
            <h3>实时简报</h3>
            <a href="javascript:;" @click="showView()">
                数据导出
            </a>
            <span>更新时间 ：{{model ? model.created_at:''}}</span>
        </div>
        <div
            class="real-box"
            v-if="model"
        >
            <!-- left -->
            <div
                class="b-x"
                style="border-right:1px solid #d9d9d9;"
            >
                <div class="b-x-x">
                    <div class="l">总营业额</div>
                    <div class="ll">
                        <span class="llt">{{model.now}}</span>
                        <span class="l-t t">{{model.nowPrice}}</span>
                    </div>
                    <div class="ll">
                        <span class="llt">{{model.first}}</span>
                        <div
                            class="t-b"
                            style="font-size:23px;"
                        >{{model.firstPrice}}
                            <span
                                style="font-size:11px;"
                                :class="model._zb >0 ?'red':model._zb < 0 ?'green':''"
                            >
                                ({{model._hb}})
                            </span></div>
                    </div>
                </div>
                <div class="b-x-x" style="margin:0;">
                    <div class="l"></div>
                    <div class="ll ll-t" style="display:flex;
                    align-items: center;
    justify-content: space-between;">
                        <div class="pt-b">
                            <p>微信:<span class="pt">{{model._price.weChat}}</span></p>
                            <p>支付宝:<span class="pt">{{model._price.ali}}</span></p>
                        </div>
                        <div class="pt-b">
                            <p>储值:<span class="pt">{{model._price.cz}}</span></p>
                            <p>现金:<span class="pt">{{model._price.xj}}</span></p>
                        </div>
                    </div>
                </div>

                <div class="b-x-x" v-if="param.storeId == 0">
                    <div class="l">会员储值</div>
                    <div class="ll ll-t">
                        <span class="lltt t-b">{{model.modelPrice}}</span>
                        <el-progress
                            :percentage="model.modelSumPrice_ZB > 100 ?100:model.modelSumPrice_ZB "
                            color="#d9d9d9"
                        ></el-progress>
                        <span class="llttt">{{model.memberPriceDes}}</span>
                    </div>
                </div>

            </div>
            <!-- right -->
            <div class="b-x">
                <div
                    class="u-x-x"
                    style="margin-bottom:20px;"
                >
                    <div class="u-l">
                        <div class="u-i"></div>
                        <div class="u-ii">客单总数(笔)</div>
                        <div class="u-ii">商品总销量</div>
                    </div>
                    <div class="u-l">
                        <div class="u-i">{{model.now}}</div>
                        <div class="u-ii ua t">{{model.nowOrderSum}}</div>
                        <div class="u-ii ua t">{{model.nowShopSum}}</div>
                    </div>
                    <div class="u-l">
                        <div class="u-i">{{model.first}}</div>
                        <div class="u-ii t-b">{{model.firstOrderSum}}</div>
                        <div class="u-ii t-b">{{model.firstShopSum}}</div>
                    </div>
                </div>
                <div class="u-x-x">
                    <div class="u-l">
                        <div class="u-i"></div>
                        <div class="u-ii">客单价</div>
                        <div class="u-ii">消费会员数</div>
                    </div>
                    <div class="u-l">
                        <div class="u-i">{{model.now}}</div>
                        <div class="u-ii ua t">{{model.nowKDJ}}</div>
                        <div class="u-ii ua t">{{model.lastPayMemberSum}}</div>
                    </div>
                    <div class="u-l">
                        <div class="u-i">{{model.first}}</div>
                        <div class="u-ii t-b">{{model.firstKDJ}}</div>
                        <div class="u-ii t-b">{{model.firstPayMemberSum}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ChartReal',
  props: {
    real: {
      type: Object
    },
    param: {
      type: Object
    }
  },
  data () {
    return {
      model: null
    }
  },

  mounted () {
    if (this.real) {
      this.model = this.real
    }
  },
  watch: {
    real (real) {
      this.model = real
    }
  },
  methods: {
    showView () {
      this.$emit('downloadInfoData', 'infoData')
    }
  }
}
</script>
<style lang='scss' scoped>
.real-b-h {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        color: #999;
    }
}

.real-box {
    display: flex;
    .b-x {
        width: 50%;
        min-height: 300px;
        .b-x-x {
            margin-top: 40px;
            display: flex;
            align-items: center;
            .l {
                text-align: right;
                margin-right: 20px;
                color: #666;
                width: 20%;
                font-size: 13px;
            }
        }
        .u-x-x {
            margin-top: 40px;
            margin-left: 30px;
            .u-l {
                margin-top: 10px;
                width: 100%;
                display: flex;
                align-items: center;
                color: #666;
                font-size: 17px;
                height: 30px;
                .u-i {
                    text-align: right;
                    margin-right: 20px;
                    width: 20%;
                }
                .u-ii {
                    width: 40%;
                    font-weight: 700;
                }
                .ua {
                    font-size: 26px;
                    color: #000;
                    font-weight: 700;
                }
            }
        }
    }
}
.ll {
    width: 40%;
    .l-t {
        display: block;
        font-weight: 700;
        font-size: 30px;
    }
    .llt {
        color: #999;
        font-size: 13px !important;
    }
    .lltt {
        font-size: 16px !important;
    }
    .llttt {
        font-size: 10px;
        font-family: "DIN-Bold" !important;
        color: #999;
    }
}
.ll-t {
    width: 80% !important;
}
.pt-b{
    width: 50%;
    color:#666;
}
.pt{
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: 'DIN-Bold';
    color: #017aff !important;
    text-shadow: 0.03em 0.03em 0.03em rgba(1, 122, 255, 0.2);
}
</style>
