<template>
  <el-main class="box report"
   v-loading="loading">
      <div class="tt" style="margin-bottom:10px">
          <el-select
          @change="searchGo"
          v-model="param.storeId" placeholder="请选择店铺">
            <el-option
              v-for="item in model.stores"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
      <div>
        <el-button size="mini" :class="selectTime === 1?'active':''" round @click="getTimeData(1)">日</el-button>
        <el-button size="mini" :class="selectTime === 2?'active':''" round @click="getTimeData(2)">周</el-button>
        <el-button size="mini" :class="selectTime === 3?'active':''" round @click="getTimeData(3)">月</el-button>
        <el-date-picker
          @change="getTimeData(4)"
          :class="selectTime === 4?'active':''"
          style="margin-left:20px;"
          v-model="param.selectTime"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        </div>
      </div>
      <real ref="real"
      :param="param"
      :real="real" style="margin-bottom:50px"
      @downloadInfoData="downloadInfoData"/>
      <!-- 营业 -->
      <order ref="order"  @showView="showView"
      v-if="divine" :divine="divine" style="margin-bottom:50px"/>
      <!-- 商品 -->
      <shop ref="shop"
       @showView="showView"
       v-if="shop" :shop="shop" style="margin-bottom:50px"/>
      <!-- 会员 -->
      <div v-if="param.storeId == 0">
        <member ref="member"
        @showView="showView"
        v-if="member"
        :member="member"
        style="margin-bottom:50px"/>
      </div>
      <!-- 其他 -->
      <other    @showView="showView"
      ref="other"  v-if="other" :other="other"/>
      <!-- boxboxbox -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="70%"
        >
        <ViewBox ref="pcView"/>
      </el-dialog>

  </el-main>
</template>

<script>
import real from './real'
import order from './order'
import shop from './shop'
import member from './member'
import other from './other'
import ReportService from '@admin/services/ReportService'
import flatry from '@admin/utils/flatry'
import ViewBox from './pc-view'

export default {
  name: 'ReportIndex',
  data () {
    return {
      typeName: '',
      dialogVisible: false,
      loading: false,
      model: {
        stores: []
      },
      param: {
        storeId: '0',
        selectTime: [],
        type: 1
      },
      selectTime: 1,
      divine: null,
      shop: null,
      member: null,
      other: null,
      real: null,
      baseData: null
    }
  },

  components: { order, shop, member, other, real, ViewBox },

  async created () {
    this.loading = true
    const { data } = await flatry(ReportService.all())
    if (data) {
      this.baseData = data.data
      window.localStorage.setItem('reportData', JSON.stringify(data.data))
      window.localStorage.setItem('selectType', JSON.stringify(this.param))
      this.model.stores = data.data.stores
      this.divine = data.data.divine
      this.shop = data.data.shop
      this.member = data.data.users
      this.other = data.data.other
      this.real = data.data.real
    }
    this.loading = false
  },

  methods: {
    showView (val) {
      let _this = this
      setTimeout(() => {
        console.log(_this.$refs.pcView)
        _this.$refs.pcView.initData(_this.baseData)
      }, 10)
      this.dialogVisible = true
    },
    getTimeData (type) {
      this.selectTime = type
      this.param.type = type
      window.localStorage.setItem('selectType', JSON.stringify(this.param))
      this.searchGo()
    },
    async searchGo () {
      this.loading = true
      const { data } = await flatry(ReportService.getData(this.param))
      if (data) {
        this.baseData = data.data
        window.localStorage.setItem('reportData', JSON.stringify(data.data))
        this.divine = data.data.divine
        this.shop = data.data.shop
        this.member = data.data.users
        this.other = data.data.other
        this.real = data.data.real
      }
      this.loading = false
    },
    async downloadInfoData () {
      let param = {
        type: 'infoData',
        time: this.param
      }
      const { data } = await flatry(ReportService.downloadFile(null, param))
      if (data) {
        console.log(data)
      }
    }
  }

}

</script>
<style lang='scss' scoped>
.tt{
  display: flex;
  justify-content: space-between;
}
.active{
  color: #409eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}
</style>
