<template>
  <div >
    <h2>其他</h2>
    <div class="bing">
      <div class="i-box">
        <h4>优惠劵使用情况</h4>
        <div
          class="use-box"
          v-if="couponSum"
        >
          <div class="u-b-i">
            <div>
              <span class="u-b-im t">{{couponSum.send || 0}}</span>
              <span>发劵量(张)</span>
            </div>
            <div>
              <span class="u-b-im t">{{couponSum.sendSum || 0}}</span>
              <span>发劵总量(张)</span>
            </div>
          </div>
          <div class="u-b-i">
            <div>
              <span class="u-b-im t">{{couponSum.use}}</span>
              <span>用劵量(张)</span>
            </div>
            <div>
              <span class="u-b-im t">{{couponSum.useSum}}</span>
              <span>用劵总量(张)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="i-box">
        <div class="bp-ms">
          <h4>优惠劵使用排行榜</h4>
          <a href="javascript:;" @click="showView('coupon')">
            查看详情
          </a>
        </div>
        <ve-histogram
          :y-axis="yAxis"
          :grid="grid"
          :colors="colors"
          :data="bar"
          :extend="chartExtend"
        ></ve-histogram>
      </div>
    </div>
    <div>
      <h4>敏感操作</h4>
      <el-table
        :data="tableData"
        class="report-shop-table"
        style="width: 100%;background#f8f8f8;height:300px;"
      >
        <el-table-column
          prop="name"
          label="操作"
        >
        </el-table-column>

        <el-table-column
          label="单数"
          prop="orderSum"
        >
        </el-table-column>

        <el-table-column
          label="商品数量"
          prop="shopSum"
        >
        </el-table-column>
        <el-table-column
          prop="price"
          label="金额(元)"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reportOrder',
  props: {
    other: {
      type: Object
    }
  },
  data () {
    this.colors = ['#017AFF',
      '#FFE6C3', '#ddd']

    this.grid = {
      show: true
    }
    this.yAxis = {
      splitLine: {
        show: false
      }
    }
    this.chartExtend = {
      legend: {
        orient: 'vertical',
        x: 'right'
      }
    }
    return {
      couponSum: null,
      bar: null,
      tableData: null
    }
  },
  mounted () {
    this.couponSum = this.other.count
    this.bar = this.other.bar
    this.tableData = this.other.tableData
  },
  watch: {
    other (other) {
      this.couponSum = other.count
      this.bar = other.bar
      this.tableData = other.tableData
    }
  },
  methods: {
    showView (val) {
      this.$emit('showView', val)
    }
  }
}
</script>
<style lang='scss' scoped>
.bing {
    display: flex;
    .i-box {
        width: 50%;
    }
}
.use-box {
    margin-top: 80px;
    display: flex;
    .u-b-i {
        width: 50%;
        div {
            margin-bottom: 20px;
            span {
                color: #888;
            }
        }
    }
    .u-b-im {
        display: block;
        font-size: 37px;
        color: #222;
    }
}
.report-shop-table {
    overflow-y: auto;
    max-height: 500px;
}
</style>
