import Vue from 'vue'

const OrderService = {
  all (param) {
    return Vue.http.get('report/index', param)
  },
  getData (param) {
    return Vue.http.post('report/index', param)
  },
  getDataView (param) {
    return Vue.http.get('report/pc-view', param)
  },
  downloadFile (param = null, data = null) {
    return Vue.http.download('POST', 'export/index', param, data)
  }
}

export default OrderService
