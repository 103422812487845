<template>
  <div>
    <h2>营业</h2>
    <div>
      <div class="bp-ms">
        <h4>销售额走势</h4>
        <a href="javascript:;" @click="showView('xs')">
          查看详情
        </a>
      </div>
      <ve-line
        :grid="grid"
        :data="orderData"
        :y-axis="yAxis"
        :colors="colorsOne"
      ></ve-line>
    </div>
    <div>
      <ve-line
        :grid="grid"
        :y-axis="yAxis"
        :data="orderTwoData"
        :extend="lineExtend"
        :colors="['#017AFF']"
      ></ve-line>
    </div>
    <div class="bing">
      <div class="">
        <h4>会员与非会员消费占比</h4>
        <ve-ring
          :colors="colorsTwo"
          :extend="chartExtend"
          :data="memberData"
        ></ve-ring>
      </div>
      <div>
        <div class="bp-ms" style="width:100%;">
          <h4>分店销售额</h4>
          <a href="javascript:;" @click="showView('store')">
            查看详情
          </a>
        </div>
        <ve-histogram
          :y-axis="yAxis"
          :grid="grid"
          :colors="colorsTwo"
          :data="storeData"
        ></ve-histogram>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reportOrder',
  props: {
    divine: {
      type: Object
    }
  },
  data () {
    this.colorsOne = ['#017AFF', '#057AFF60']
    this.colorsTwo = ['#017AFF', '#FFE6C3']
    this.lineExtend = {
      series: {
        type: 'line',
        smooth: 0 // 是否平滑曲线显示
      }
    }
    this.chartExtend = {
      legend: {
        orient: 'vertical',
        x: 'left'
      },
      series: {
        name: '消费占比',
        type: 'pie',
        label: {
          normal: {
            formatter: '{b} {@[0]} 人\n消费金额 {@[1]} 元',
            color: '#333333'
          }
        }
      }
    }
    this.grid = {
      show: true
    }
    this.yAxis = {
      splitLine: {
        show: false
      }
    }
    return {
      type: '',
      orderTwoData: null,
      orderData: null,
      memberData: null,
      storeData: null
    }
  },
  mounted () {
    this.orderTwoData = this.divine.barTwo
    this.orderData = this.divine.bar
    this.memberData = this.divine.ring
    this.storeData = this.divine.zhu
    // const data = window.localStorage.getItem('selectType')
    // let param = Object.assign({ }, JSON.parse(data))
    // console.log(param)
  },
  watch: {
    divine (divine) {
      this.orderTwoData = this.divine.barTwo
      this.orderData = divine.bar
      this.memberData = divine.ring
      this.storeData = divine.zhu
    }
  },
  methods: {
    showView (val) {
      this.$emit('showView', val)
    }
  }
}
</script>
<style lang='scss' scoped>
.bing {
    display: flex;
    div {
        width: 50%;
    }
}
</style>
